import React, { useState, useRef } from "react";

const VideoList = ({ videos, nom, nombre }) => {
  const [activeVideo, setActiveVideo] = useState(null);
  const vids = useRef([])

  const handlePlay = (index) => {
    if(activeVideo != null && activeVideo != index){
        vids.current[activeVideo].pause()
    }
    setActiveVideo(index);
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px'}}>
      {videos.map((video, index) => (
        <div
          key={index}
          style={{
            flex: '1 1 calc(33.333% - 16px)',
            display: "flex",
            alignItems: "center",
            gap: "12px",
            padding: "12px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "all 0.3s ease",
            backgroundColor: activeVideo === index ? "#f9f9f9" : "white",
          }}
        >
          <video
            src={video}
            ref={ element => vids.current.splice(index,1,element) }
            controls
            muted
            onPlay={() => handlePlay(index)}
            style={{
              width: activeVideo === index ? "400px" : "200px",
              borderRadius: "8px",
              transition: "width 0.3s ease",
            }}
          />
          <div>
            <h4 style={{ margin: 0 }}>Portée de {nom}</h4>
            <p style={{ margin: "4px" }}>Vidéo de la portée de {nom} dont {nombre} chiots sont disponibles</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VideoList;