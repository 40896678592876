import React from 'react';
import { Helmet } from "react-helmet";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

import VideoList from './VideoList';

import video5 from './video/naissance_Usher2.mp4';
import video6 from './video/naissance_Usher3.mp4';
import video7 from './video/naissance_Usher4.mp4';
import video8 from './video/naissance_Usher5.mp4';

import './Births.css'

const videos_Usher = [video5, video6, video7, video8]

const images2 = require.context('./img/golden/naissance/naissance_usher', true);
const imageList2 = images2.keys().map(image2 => images2(image2));

const Births = () => {
  return (
    <div className="container">
      <Helmet>
        <title>Dernières naissances de chiots golden retriever</title>
        <meta
          name="description"
          content="Retrouvez l'actualité des dernières naissances de chiots golden retriever de travail dans le Puy-de-dôme de l'élevage au travers de photos et de vidéos."
        />
      </Helmet>
      <h1 className='mb-3'>Carnet de naissances</h1>
        <Tabs>
          {/*
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
            {videos_Scapa.map((video, index) => (
              <div key={index} style={{ flex: '1 1 calc(33.333% - 16px)' }}>
                <video
                  src={video}
                  controls
                  muted
                  style={{ width: '100%', borderRadius: '8px' }}
                />
              </div>
            ))}
          </div>
          */}
          {/*
          <div className='d-flex justify-content-center mt-3'>
            <Carousel className='d-flex justify-content-center' data-bs-theme="dark" fade interval={null}>
                {videos_Scapa.map((video) => (
                    <Carousel.Item className='d-flex justify-content-center'>
                        <video className="justify-content-center" style={{objectFit:"contain"}} src={video} muted controls/>
                    </Carousel.Item>
                ))}
            </Carousel>
          </div>
          */}
        <Tab eventKey="Usher" title="Usher">
          <Row>
            <Col>
              <VideoList videos={videos_Usher} nom="Usher" nombre="1"/>
            </Col>
            <Col>
              <div className='d-flex justify-content-center'>
                <Carousel fade>
                    {imageList2.map((image) => (
                        <Carousel.Item>
                            <img loading='lazy' className="d-block w-100" style={{objectFit:"contain"}} src={image} alt="Elevage de golden retriever"/>
                        </Carousel.Item>
                    ))}
                </Carousel>
              </div>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Births;
